import * as React from 'react';
import { withTranslation, WithTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { formatNumber, formatDate } from '../../i18n';
import { ISentNotification } from "./galleryWrapperPage";
import { Box, Button, Flex, FlexItem, Image, Label, Text, Loader, Dialog, Tooltip, EyeIcon, PlayIcon, AttendeeIcon, Popup, ReactionsIcon } from "@fluentui/react-northstar";
import { NotificationsType } from '../../utility/SharedEnum';
import ReactionsPopUp from '../Shared/ReactionsPopUp/reactionsPopUp';
import "./cardView.scss";
import { TooltipHost } from 'office-ui-fabric-react';
import MessageCardView from './Cards/messageCard';
import PollCardView from './Cards/pollCard';

interface CardViewProps extends WithTranslation {
    cardDetails: ISentNotification;
    index: number;
}

export interface ICardViewState {
    cardDetails: ISentNotification;
}

class CardView extends React.Component<CardViewProps, ICardViewState> {
    readonly localize: TFunction;

    constructor(props: CardViewProps) {
        super(props);

        this.localize = this.props.t;
        this.state = {
            cardDetails: this.props.cardDetails,
        }
    }

    /**
    * Renders the component
    */
    public render(): JSX.Element {
        return (
            <div id={this.props.index.toString()} className="card-bg">                
                <div className="card-border">
                    <div className="card-body">
                        {(this.state.cardDetails?.type == NotificationsType.Message || this.state.cardDetails?.type === null) &&
                            <MessageCardView cardDetails={this.state.cardDetails} index={this.props.index} />                            
                        }

                        {/* RENDER TYPE POLL */}
                        {/*{this.state.cardDetails?.type == NotificationsType.Poll &&*/}
                        {/*    <PollCardView cardDetails={this.state.cardDetails} index={this.props.index} />*/}
                        {/*}*/}
                        
                    </div>
                    <div className="footer-flex">
                        
                        <Flex gap="gap.small">
                            <FlexItem>
                                <div>
                                    <TooltipHost content={this.localize("Views")} calloutProps={{ gapSpace: 0 }}>
                                        <EyeIcon xSpacing="after" className="iconGalery" outline />
                                        <span className="semiBold">{formatNumber(this.state.cardDetails.views)}</span>
                                    </TooltipHost>

                                    {(!this.state.cardDetails.type || this.state.cardDetails.type == NotificationsType.Message) &&
                                        <TooltipHost content={this.localize("Clicks")} calloutProps={{ gapSpace: 0 }}>
                                            <PlayIcon xSpacing="both" className="iconGalery" outline />
                                            <span className="semiBold">{formatNumber(this.state.cardDetails.clicks)}</span>
                                        </TooltipHost>
                                    }

                                    {(this.state.cardDetails.type == NotificationsType.Poll || this.state.cardDetails.type == NotificationsType.Quiz) &&
                                        <TooltipHost content={this.localize("Participants")} calloutProps={{ gapSpace: 0 }}>
                                            <AttendeeIcon xSpacing="both" className="iconGalery" outline />
                                            <span className="semiBold">{formatNumber(this.state.cardDetails.participants)}</span>
                                        </TooltipHost>
                                    }

                                    {this.state.cardDetails.reactions != null && this.state.cardDetails.reactions.total >= 0 ?
                                        <Popup
                                            content={
                                                <ReactionsPopUp message={this.state.cardDetails} />
                                            }
                                            on='hover'
                                            position='after'
                                            pointing={true}
                                            mouseLeaveDelay={0}
                                            trigger={
                                                <Box className='box-reactions-icon'>
                                                    <ReactionsIcon xSpacing="both" className="iconGalery" outline />
                                                    <span className="semiBold">{formatNumber(this.state.cardDetails.reactions.total)}</span>
                                                </Box>
                                            }
                                        />
                                        :
                                        <Box className='box-reactions-icon'>
                                            <ReactionsIcon xSpacing="both" className="iconGalery" outline />
                                            <span className="semiBold">{this.state.cardDetails.reactions != null ? formatNumber(this.state.cardDetails.reactions.total) : "-"}</span>
                                        </Box>
                                    }
                                </div>
                            </FlexItem>
                            <FlexItem push>
                                <Flex gap="gap.smaller" vAlign="center" hAlign="end">
                                    <Text size="small" content={formatDate(this.state.cardDetails.sentDate)}></Text>
                                </Flex>
                            </FlexItem>
                        </Flex>
                    </div>
                </div>
            </div>
        );
    }
}

const CardViewWithTranslation = withTranslation()(CardView);
export default CardViewWithTranslation;